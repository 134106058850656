import React from 'react'
import houseCheers from '../../assets/houseCheers.svg'
import compassMap from '../../assets/compassMap.svg'
import videoPeople from '../../assets/videoPeople.svg'
import './infoIcons.css'

const InfoIcon = ({icon, description}) => {
  return (
    <div className='infoIcon'>
      <img src={icon} alt='icon'/>
      <p>{description}</p>
    </div>
  )
}


const InfoIcons = () => {
  return (
    <div className='infoIcons'>
      <InfoIcon icon={houseCheers} description = 'A hub for nightlife venues & information'/>
      <InfoIcon icon={compassMap} description = 'Discover new nightlife based on your network '/>
      <InfoIcon icon={videoPeople} description = 'Share a glimpse of your night out with friends'/>
    </div>
  )
}

export default InfoIcons