import React, { useState, useEffect, lazy, Suspense } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

const Desktop = lazy(() => import('../desktop/Desktop'));
const Mobile = lazy(() => import('../mobile/Mobile'));
const DesktopEarlyAccess = lazy(() => import('../desktopEarlyAccess/DesktopEarlyAccess'));
const Terms = lazy(() => import('../terms/Terms'));
const PrivacyPolicy = lazy(() => import('../privacyPolicy/PrivacyPolicy'));



const NightspotRoutes = () => {

  const location = useLocation();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes location={location} key={location.pathname}>
        {screenWidth < 832 ? (
          <Route path="/" element={<Mobile />} />
        ) : (
          <Route path="/" element={<Desktop />} />
        )}
         <Route path="/earlyAccess" element={<DesktopEarlyAccess />} />
         <Route path="/terms" element={<Terms />} />
         <Route path="/privacyPolicy" element={<PrivacyPolicy />} />

      </Routes>
    </Suspense>
  );
}

export default NightspotRoutes;