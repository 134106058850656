import instagramUnhovered from '../../assets/instagramUnhovered.svg'
import instagramHovered from '../../assets/instagramHovered.svg'
import tiktokUnhovered from '../../assets/tiktokUnhovered.svg'
import tiktokHovered from '../../assets/tiktokHovered.svg'
import spotifyUnhovered from '../../assets/spotifyUnhovered.svg'
import spotifyHovered from '../../assets/spotifyHovered.svg'
import { useRef, React } from 'react';

const ImageToggleOnMouseOver = ({primaryImg, secondaryImg}) => {
    const imageRef = useRef(null);
  
    return (
      <img 
        onMouseOver={() => {
          imageRef.current.src = secondaryImg;
        }}
        onMouseOut={() => {
          imageRef.current.src= primaryImg;
        }}
        src={primaryImg} 
        alt=""
        ref={imageRef}
      />
    )
  }

  const HoverSocial = ({ type }) => {
    let primaryImg;
    let secondaryImg;
  
    switch (type) {
      case 'instagram':
        primaryImg = instagramUnhovered;
        secondaryImg = instagramHovered;
        break;
      case 'tiktok':
        primaryImg = tiktokUnhovered;
        secondaryImg = tiktokHovered;
        break;
      case 'spotify':
        primaryImg = spotifyUnhovered;
        secondaryImg = spotifyHovered;
        break;
      default:
        primaryImg = '';
        secondaryImg = '';
    }
  
    return (
      <div>
        <ImageToggleOnMouseOver primaryImg={primaryImg} secondaryImg={secondaryImg} />
      </div>
    );
  };

export default HoverSocial