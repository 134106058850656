import React, { useEffect, useRef} from 'react'
import './desktop.css';
import logo from '../../assets/Logo.svg'
import homePage from '../../assets/homePage.png'
import searchPage from '../../assets/searchPage.png'
import profilePage from '../../assets/profilePage.png'
import friendProfilePage from '../../assets/friendProfilePage.png'
import storyPage from '../../assets/storyPage.png'
import notificationsPage from '../../assets/notificationsPage.png'
import barProfileHalf from '../../assets/barProfileHalf.png'
import barProfileFull from '../../assets/barProfileFull.png'
import barProfileMap from '../../assets/barProfileMap.png'
import barProfileHours from '../../assets/barProfileHours.png'
import storyPosting from '../../assets/storyPosting.png'
import storyPosting2 from '../../assets/storyPosting2.png'
import { HoverSocial, InfoIcons } from '../index';
import { NavLink } from 'react-router-dom';

const imagesLeft = [homePage, searchPage, profilePage, friendProfilePage, storyPage, notificationsPage, homePage, searchPage, profilePage, friendProfilePage, storyPage, notificationsPage];
const imagesRight = [barProfileHalf, barProfileFull, barProfileMap, barProfileHours, storyPosting, storyPosting2];



const Desktop = () => {

  
  const leftContainerRef = useRef(null);
  const rigthContainerRef = useRef(null);

  useEffect(() => {
    const container = leftContainerRef.current;
    const images = container.querySelectorAll('.scroll-image-left');
    for (let i = 0; i < 4; i++) {
      container.appendChild(images[i].cloneNode(true)); // Clone the first four images to create a seamless loop
    }

    const resetScroll = () => {
      container.style.animation = 'none';
      void container.offsetHeight; // Trigger reflow
      container.style.animation = '';
    };

    container.addEventListener('animationiteration', resetScroll);

    return () => {
      container.removeEventListener('animationiteration', resetScroll);
    };
  }, []);

  useEffect(() => {
    const container = rigthContainerRef.current;
    const images = container.querySelectorAll('.scroll-image-right');
    for (let i = 0; i < 4; i++) {
      container.appendChild(images[i].cloneNode(true)); // Clone the first four images to create a seamless loop
    }

    const resetScroll = () => {
      container.style.animation = 'none';
      void container.offsetHeight; // Trigger reflow
      container.style.animation = '';
    };

    container.addEventListener('animationiteration', resetScroll);

    return () => {
      container.removeEventListener('animationiteration', resetScroll);
    };
  }, []);



  return (
    <div className='desktop'>
        <div className='nightspotInfo'>
          <div>
          <div className='navbar'>
            <img src={logo} alt='logo' />
            <div className='socials'>
              <HoverSocial type='instagram'/>
              <HoverSocial type='tiktok'/>
              <HoverSocial type='spotify'/>
            </div>
          </div>
          <div className='nightspotDetails'>
            <div>
              <h1>The <br/> Nightlife App </h1>
              <p>Nightlife has always been chaotic, with last minute plan changes and limited access to information on bars and clubs in your city. 
              So we built an app that makes nightlife easier.</p>
              <InfoIcons/>
              <div className='nightspotButtonContainer'>
                <NavLink to='/earlyAccess'><button className="earlyAccessButton">Get Early Access</button></NavLink>
                <a href="mailto:join@nightspot.app" className="contactButton">Contact Us</a>
              </div>
            </div>
          </div>
          </div>
          <div className='footer'>
              <NavLink to='/privacyPolicy'><p>Privacy Policy</p></NavLink>
              <span class="separator">|</span>
              <NavLink to='/terms'><p>Terms</p></NavLink>
          </div>
        </div>
        <div className='imageCarousel'>
        <div class="image-container-left" ref={leftContainerRef}>
        {imagesLeft.map((src, index) => (
            <img key={index} src={src} alt={` ${index}`} className='scroll-image-left' />
          ))}
      </div>
      <div class="image-container-right" ref={rigthContainerRef}>
        {imagesRight.map((src, index) => (
            <img key={index} src={src} alt={` ${index}`} className='scroll-image-right' />
          ))}
          
      </div>
      </div>
    </div>
  )
}

export default Desktop