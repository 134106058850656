import React, { useState } from 'react';
import { BrowserRouter as Router} from 'react-router-dom'
import { AgeVerification, NightspotRoutes } from './components';
import './App.css';

function App() {


  const [isVerified, setIsVerified] = useState(false);

  
  
  const handleVerification = ( response ) => {
    if (response) {
      setIsVerified(true);
    } else {
      alert("You must be 21 or older to use the Nightlife App.");
      window.location.href = 'https://www.google.com';
    }
  };

  if (!isVerified) {
    return <AgeVerification handleVerification={handleVerification} />;
  }


  return (
    <div className="App">
      <Router>
        <NightspotRoutes/>
      </Router>
    </div>
  );
}

export default App;
