import React from 'react'
import { Carousel } from '../index'
import { HoverSocial } from '../index';
import logo from '../../assets/Logo.svg'
import './mobile.css'

const Mobile = () => {
  return (
    <div className='mobile'>
      <div className='mobileNavbar'>
        <img src={logo} alt='logo' />
        <div className='socials'>
          <HoverSocial type='instagram'/>
          <HoverSocial type='tiktok'/>
          <HoverSocial type='spotify'/>
        </div>
      </div>
      <Carousel/>
    </div>
  )
}

export default Mobile