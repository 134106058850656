import React from 'react';
import './earlyAccessComponent.css';
import { BsCheckLg } from 'react-icons/bs';


function formatNumber(id, value){
  if (!value) return value;
  if (id !== 2){
    return value
  }
  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0,3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0,3)}) ${phoneNumber.slice(3,6)}-${phoneNumber.slice(6,10)}`;
}




const EarlyAccessComponent = (props) => {
  const {label, errorMessage, onChange, value, id, ...inputProps } = props;
  return (
    <div className="WaitlistInput">
      <div className='wrap12'>
        <div className='wrap32'>
          <input value={formatNumber(id, value)} {...inputProps} onChange={onChange} required/>
          <span className='check2'><BsCheckLg/></span>
        </div>
      </div>
    </div>
)}

export default EarlyAccessComponent