import React from 'react'
import './ageVerification.css';
import logo from '../../assets/Logo.svg'

const AgeVerification = ({ handleVerification }) => {
  return (
    <div className="age-verification-overlay">
      <div className="age-verification-content">
      <img src={logo} alt="Nightlife Logo" className='logo'/>
        <h1>Are you 21 or older?</h1>
        <p>You must be 21 or older <br/> to use the Nightlife App</p>
        <div className='buttonContainer'>
            <button onClick={() => handleVerification(false)}>No</button>
            <button className="gradientButton" onClick={() => handleVerification(true)}>Yes</button>
        </div>
      </div>
    </div>
  )
}

export default AgeVerification