import React from 'react'
import './carouselItem.css'

const CarouselItem = ({ item, width }) => {
  return (
    <div className='carousel-item' style={{width: width}}>
      <img className='carousel-img' src={item.icon} alt='icon'/>
      <div className={`${item.title==="Discover New Nightlife" ? "overlap-4": "overlap"}`}>
        <div className='carousel-item-title'>{item.title}</div>
        <div className='carousel-item-description'>{item.description}</div>
      </div>
    </div>
  )
}

export default CarouselItem