import React, { useState } from 'react'
import { CarouselItem } from '../index'
import { NavLink } from 'react-router-dom'
import { GoDotFill } from "react-icons/go";
import { useSwipeable } from 'react-swipeable';
import './carousel.css'

const Carousel = () => {


  const [activeIndex, setActiveIndex] = useState(0);

  const items = [
    {
      title: 'The\nNightlife App',
      description: 'We’re a nightlife social network that helps you create memorable experiences by connecting you with your friends ',
      icon: require('../../assets/carouselImage1.png')
    },
    {
      title: 'Share Your Night Out',
      description: 'Whenever you go to a bar, The Nightlife App detects your location and lets you share a video and describe what its like',
      icon: require('../../assets/carouselImage2.png')
    },
    {
      title: 'Get Real-Time Upates',
      description: 'Get a real-time look at your favorite bar, including who’s there, how busy it is, and what the vibe is like',
      icon: require('../../assets/carouselImage3.png')
    },
    {
      title: 'Discover New Nightlife',
      description: 'The Nightlife App is currently available in Washington, DC. Click below to get early access',
      icon: require('../../assets/carouselImage4.png')
    },
  ];

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
        newIndex = 0;
    }else if (newIndex >= items.length) {
        newIndex = items.length - 1;
    }
    setActiveIndex(newIndex);
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  return (
      <div className='carousel' {...handlers}>
        <div className='inner'
        style={{transform: `translateX(-${activeIndex * 100}%)`}}>
          {items.map((item)=>{
            return <CarouselItem item={item} width={"100%"}/>;
          })}
        </div>
        <div className={`carousel-buttons  ${activeIndex === 3 ? 'carousel-buttons-margin' : ''}`}>
          <div className='indicators'>
            {items.map((item, index) => {
                return (
                    <button onClick={()=>{
                        updateIndex(index);
                      }}
                    className='indicator-buttons'>
                    <span className={`material-symbols-outlined ${index===activeIndex? "indicator-symbol-active": "indicator-symbol"}`}>
                    <GoDotFill />
                    </span>
                  </button>
                );
            })}
          </div>
          {activeIndex===3 ? 
          <div className='buttonContainerCarousel'>
            <NavLink to='/earlyAccess'><button onClick={()=>{
                updateIndex(activeIndex + 1);
            }}
            className='early-access-button'>
                Get Early Access
            </button></NavLink>
            <a href="mailto:join@nightspot.app"> 
            <button onClick={()=>{
                updateIndex(activeIndex + 1);
            }}
            className='continue-button'>
                Contact Us
            </button></a>
          </div>
          
          :
          <button onClick={()=>{
            updateIndex(activeIndex + 1);
          }}
          className='continue-button'>
            Continue
          </button>}
        </div>
      </div>
    )
}


export default Carousel