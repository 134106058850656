import React, { useState } from 'react'
import logo from '../../assets/Logo.svg'
import './desktopEarlyAccess.css'
import { HoverSocial } from '../index';
import { NavLink } from 'react-router-dom';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';
import { EarlyAccessComponent } from '../index';



const DesktopEarlyAccess = (props) => {

  //const [hovered, setHovered] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [is21Checked, setIs21Checked] = useState(false);
  const [termAndServices, setTermAndServices] = useState(false);

  const handle21Change = (event) => {
    setIs21Checked(event.target.checked);
  };

  const handleTermAndServicesChange = (event) => {
    setTermAndServices(event.target.checked);
  };

  const [values, setValues] = useState({
    fullname:"",
    number:"",
    city: ""
  });

  const inputs = [
    {
      id:1,
      name:"fullname",
      type:"text",
      placeholder:"Full Name",
      errorMessage:"Enter full name",
      pattern: "^[A-Za-z][A-Za-z]*[ ][A-Za-z][A-Za-z ]*",
      required: true,
    },
    {
      id:2,
      name:"number",
      type:"tel",
      placeholder:"Phone Number",
      errorMessage:"Enter valid phone number",
      required: true,
      pattern: "^[(][0-9]{3}[)][ ][0-9]{3}[-][0-9]{4}",
    },
    {
      id:3,
      name:"city",
      type:"text",
      placeholder:"What City do you live in",
      required: true,
    },
  ]

  const handleChange = (e) => {
    e.preventDefault();
  }
 
  
  const handleSubmit = (e)=>{
    setDisabled(true)
    e.preventDefault();
    const newUser = {
      fullname: values.fullname,
      number: values.number,
      city: values.city,
    }
    console.log(newUser)
  }

  const handleInput = (e) => {
    if (e.target.name === "number"){ 
      const formattedNumber = formatNumber(e.target.value);
      setValues({...values, [e.target.name]: formattedNumber})
    }else{
      setValues({...values, [e.target.name]: e.target.value})
    }
  }

  const onChange = (e)=>{
    const BreakError = new Error("Form validation failed");
    handleInput(e);
    const forms = document.querySelectorAll('form');
    let allValid = true;
    forms.forEach((form) => {
      const inputs = form.querySelectorAll('input');
      try {
        inputs.forEach((input) => {
          if (!input.checkValidity()) {
            allValid = false;
            throw BreakError;
          } 
        });
        }catch (error) {
        console.error(error.message);
      }
    });
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    try {
      checkboxes.forEach((checkbox) => { 
        if (!checkbox.checked) {
          allValid = false;
          throw BreakError;
        }
      });
    }catch (error) { console.error(error.message)}
    if (allValid) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }

  //const style = hovered ? { visibility: 'visible' } : { visibility: 'hidden'};




  return (
    <div className='desktopEarlyAccess'>
      <div className='joinCommunity'>
        <div className='navbarEarlyAccess'>
          <img src={logo} alt='logo' />
          <div className='socials'>
            <HoverSocial type='instagram'/>
            <HoverSocial type='tiktok'/>
            <HoverSocial type='spotify'/>
          </div>
        </div>
        <div className='joinCommunityContainer'>
          <NavLink to='/'><button className="backButton"><span className="backIcon"><HiOutlineArrowNarrowLeft/></span>back</button></NavLink>
          <h1 className='Join'>Join The Community</h1>
          <h1 className='signUp'>Sign Up for <br/>Early Access</h1>
          <h2>Get Early Access</h2>
          <p>Fill out the information below, and we’ll text you instructions and a link to download the app.</p>
          <form onSubmit={handleChange}>
            {inputs.map((input)=> (
                <EarlyAccessComponent 
                key={input.id}
                {...input } 
                value={values[input.name]}
                onChange={onChange}
                />
              ))}      
          </form>
          <div className='tentyOneCheck'>
            <p>Are you 21 or older?</p>
            <input
              type='checkbox'
              id='ageCheck'
              checked={is21Checked}
              onChange={(e) => {
                handle21Change(e);
                onChange(e);
              }}
            />
          </div>
          <div className='termsAndPolicy'>
            <p>I’ve read and agree to the Nightspot <br/>
              <span className='underline'>Terms of Service </span> <span className="noUnderline">&</span> <span className='underline'>Privacy Policy</span></p>
              <input
              type='checkbox'
              id='termAndService'
              checked={termAndServices}
              onChange={(e) => {
                handleTermAndServicesChange(e);
                onChange(e);
              }}
            />
          </div>
          <button className='submit' disabled={disabled} onClick={handleSubmit}>Submit </button>
        </div>
        <div className='footer'>
              <NavLink to='/privacyPolicy'><p>Privacy Policy</p></NavLink>
              <span class="separator">|</span>
              <NavLink to='/terms'><p>Terms</p></NavLink>
          </div>
      </div>  
    </div>
  )
}

function formatNumber(value){
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0,3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0,3)}) ${phoneNumber.slice(3,6)}-${phoneNumber.slice(6,10)}`;
}

export default DesktopEarlyAccess